import React, { useEffect, useRef, useState } from 'react';
import { IconLink } from 'components/icons';
import Image from 'next/image';
import Link from 'next/link';

const DATA_FEATURES = [
  {
    title: (
      <div>
        Method <br /> Sniper
      </div>
    ),
    name: 'Method Sniper',
    description: 'Buy up-coming token launches in a flash',
    image: 'method-sniper.png',
    link: 'https://docs.avabot.ai/advanced-features/method-sniper',
  },
  {
    title: (
      <div>
        Limit
        <br /> order
      </div>
    ),
    name: 'Limit Order',
    description: 'Buy low, sell high like a trading terminal',
    image: 'limit-orders.png',
    link: 'https://docs.avabot.ai/basic-features/buy-limits',
  },
  {
    title: (
      <div>
        Copy <br />
        Trade
      </div>
    ),
    name: 'Copy Trade',
    description: 'Follow the top traders, replicate their wins',
    image: 'copy-trade.png',
    link: 'https://docs.avabot.ai/advanced-features/copy-trade',
  },
  {
    title: (
      <div>
        Private <br />
        Transaction
      </div>
    ),
    name: 'Private Transaction',
    description: 'Hide your trades, outplay sandwich attacks',
    image: 'private-transactions.png',
    link: 'https://docs.avabot.ai/basic-features/user-settings',
  },
  {
    title: (
      <div>
        Front <br /> Run
      </div>
    ),
    name: 'Front Run',
    description: 'Tip the validators to get your transactions executed earlier',
    image: 'front-run.png',
    link: 'https://docs.avabot.ai/basic-features/user-settings',
  },
  {
    title: 'Anti-Rug',
    name: 'Anti-Rug',
    description: 'Protect your investment from scammers',
    image: 'anti-rug.png',
    link: 'https://docs.avabot.ai/basic-features/user-settings',
  },
];

const configSectionScroll = {
  width: 398,
  height: 600,
  length: DATA_FEATURES.length,
};

export const Feature = () => {
  const speed = 4;
  const [height, setHeight] = useState<number>(0);
  const [axisX, setAxisX] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const maxWidth = window.innerWidth >= 1920 ? 1408 : 1240;
    const widthList = configSectionScroll.width * configSectionScroll.length;
    const withOuter = widthList - maxWidth;
    const clientTop = (window.innerHeight - configSectionScroll.height) / 2;
    const clientLeft = (window.innerWidth - maxWidth) / 2;
    const h =
      withOuter * speed +
      window.innerHeight * 2 -
      (clientLeft + clientTop) * 2 +
      5;

    setHeight(h);

    const onScroll = () => {
      if (window.innerWidth <= 992) return;
      const offsetTop = ref.current?.offsetTop || 0;
      const maxTranslateX =
        (withOuter * speed +
          window.innerHeight -
          (clientLeft + clientTop) * 2) /
          speed -
        clientLeft / clientTop;

      if (window.scrollY >= offsetTop) {
        const moveX = (window.scrollY - offsetTop) / speed;
        setAxisX(() => (moveX > maxTranslateX ? maxTranslateX : moveX));
      } else {
        setAxisX(0);
      }
    };

    window.addEventListener('load', onScroll);
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('load', onScroll);
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div className="feature-wrapper" id={'feature-slide'} ref={ref}>
      <div className="feature-container" style={{ height }}>
        <div className="sticky-scroll">
          <div
            className="feature-horizontal"
            style={{ transform: `translate(${-axisX}px, 0)` }}
          >
            {DATA_FEATURES.map((item, index) => (
              <div key={index} className="feature-item">
                <Link
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                  title={item.name}
                >
                  <div className="feature-card">
                    <div className="feature-title">{item.title}</div>
                    <div className="feature-desc">{item.description}</div>
                    <div className="feature-thumb">
                      <Image
                        width={386}
                        height={386}
                        src={`/images/feature/${item.image}`}
                        alt={`feature-${index}`}
                      />
                    </div>

                    <div className="feature-link">
                      <IconLink />
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
