import React from 'react';
import { TextRoadMap } from 'components/icons';
const { Fade } = require('react-reveal');

const DATA_TIMELINE_1 = [
  {
    title: 'Method Sniper',
    desc: 'Buy up-coming token launches in a flash',
    active: true,
  },
  {
    title: 'Limit Orders',
    desc: 'Buy low, sell high like a trading terminal',
    active: true,
  },
  {
    title: 'Copy Trade',
    desc: 'Follow the top traders, replicate their wins',
    active: true,
  },
  {
    title: 'Private Transactions',
    desc: 'Hide your trades, outplay sandwich attacks',
    active: true,
  },
  {
    title: 'Anti-rug',
    desc: 'Protect your investment from scammers',
    active: true,
  },
  {
    title: 'Front-run',
    desc: 'Tip the validators to get your transactions executed earlier',
    active: true,
  },
  {
    title: 'Call Channels',
    desc: 'Auto snipe tokens mentioned in popular Telegram call channels',
    active: true,
  },
];

const DATA_TIMELINE_2 = [
  {
    title: 'Ava Scraper',
    desc: "Auto snipe tokens from the Telegram groups that you're in",
    active: true,
  },
  {
    title: 'Appstore for Bots',
    desc: 'Allow third party devs to utilize Ava Account Abstraction infrastructure to build and deploy new bots',
    active: false,
  },
  {
    title: 'AI integration',
    desc: 'Make Ava communicate in human-like conversation',
    active: false,
  },
  {
    title: 'Eearly Calls Bot',
    desc: 'Notify you with early gems from KOL and call channels',
    active: false,
  },
  {
    title: 'Call Analyser ',
    desc: 'Advanced data and analytics for any token contract',
    active: false,
  },
  {
    title: 'First or Fail',
    desc: 'Make sure your snipe is always in the first block',
    active: false,
  },
  {
    title: 'BSC Integration',
    desc: 'Become the fastest sniper in BSC',
    active: false,
  },
];

export const RoadMap = () => {
  return (
    <div className="road-map">
      <div className="road-map__container">
        <Fade top>
          <div className="road-map__sub-title">[Updating...]</div>
        </Fade>
        <Fade bottom>
          <div className="road-map__title">roadmap</div>
        </Fade>

        <div className="road-map__content-road-map">
          <div className="road-map__content">
            {DATA_TIMELINE_1.map((data, index) => (
              <div
                className={`road-map__content-item ${
                  data.active ? 'active' : 'not-active'
                }`}
                key={index}
              >
                <div className="road-map__content-title">
                  <div className="road-map__content-title--text">
                    {data.title}
                  </div>
                </div>
                <div className="road-map__content-desc">{data.desc}</div>
              </div>
            ))}
          </div>

          <div className="road-map__content">
            {DATA_TIMELINE_2.map((data, index) => (
              <div
                className={`road-map__content-item ${
                  data.active ? 'active' : 'not-active'
                }`}
                key={index}
              >
                <div className="road-map__content-title">
                  <div className="road-map__content-title--text">
                    {data.title}
                  </div>
                </div>
                <div className="road-map__content-desc">{data.desc}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="road-map__text">
        <TextRoadMap />
      </div>
    </div>
  );
};
