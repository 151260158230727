import React from 'react';
import 'swiper/css';
import 'swiper/css/scrollbar';
const { Fade, Zoom } = require('react-reveal');
import Image from 'next/image';
import Link from 'next/link';

const LIST_TOKEN = [
  {
    link: '#',
    image: 'doge.png',
  },
  {
    link: '#',
    image: 'wall-street-memes.png',
  },
  {
    link: '#',
    image: 'pepe.png',
  },
  {
    link: '#',
    image: 'sharbi.png',
  },
  {
    link: '#',
    image: 'bone-shiba-swap.png',
  },
  {
    link: '#',
    image: 'wikicat.png',
  },
  {
    link: '#',
    image: 'dogelon-mars.png',
  },
  {
    link: '#',
    image: 'milady.png',
  },
  {
    link: '#',
    image: 'floki.png',
  },
  {
    link: '#',
    image: 'sonic.png',
  },
];

export const ListToken = () => {
  return (
    <div className="list-token">
      <div className="list-token__content">
        <Fade bottom>
          <div className="list-token__title">Uncover new tokens</div>
        </Fade>

        <Zoom>
          <div className="list-token__desc">
            Ava will notify you with newly deployed tokens on Ethereum in
            realtime with her Scanner. You can effortlessly snipe these token
            with just one click.
          </div>
        </Zoom>
      </div>

      <div>
        <div className="list-token__tokens">
          {LIST_TOKEN.map((item, index) => {
            return (
              <div key={index} className="list-token__token">
                <div className="list-token__image">
                  <Image
                    width={212}
                    height={212}
                    src={`/images/tokens/${item.image}`}
                    alt={`image-${index + 1}`}
                  />
                </div>

                <div className="list-token__buy-now">Buy Now</div>
              </div>
            );
          })}
          {LIST_TOKEN.map((item, index) => {
            return (
              <div key={index} className="list-token__token">
                <div className="list-token__image">
                  <Image
                    width={212}
                    height={212}
                    src={`/images/tokens/${item.image}`}
                    alt={`image-${index + 1}`}
                  />
                </div>
                <div className="list-token__buy-now">Buy Now</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
