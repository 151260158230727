import React, { FC } from 'react';
import { TextAvabots } from 'components/icons';
const { Fade } = require('react-reveal');
import Image from 'next/image';

interface IReferral {
  handleActionLink: () => void;
}

export const Referral: FC<IReferral> = ({ handleActionLink }) => {
  return (
    <div>
      <div className="referral">
        <div className="referral__content">
          <Image
            width={142}
            height={45}
            src="/images/chat-bubble.png"
            alt="chat-bubble"
          />
          <Fade bottom>
            <div className="referral__title">
              <span>Introduce </span>her to your friends
            </div>
          </Fade>

          <div className="referral__detail">
            <div className="referral__detail--left">
              <div className="referral__info">
                <div className="referral__label">You earn</div>
                <div className="referral__value">25%</div>
              </div>
              <div className="referral__divider"></div>
              <div className="referral__info">
                <div className="referral__label">Your friends save </div>
                <div className="referral__value">10%</div>
              </div>
            </div>
            <div className="referral__image">
              <Image
                width={400}
                height={400}
                src="/images/referral.png"
                alt={'referral'}
              />
            </div>

            <div className="referral__detail--right">
              <div className="referral__desc">
                Inspired by the Ava AI robot in the movie Ex Machina, we make
                Ava with the intention for her to be your Robo advisor for
                everything crypto in the future. She's a sassy girl with a sense
                of humor and a bold personality.
              </div>

              <div
                className="referral__btn"
                onClick={() => handleActionLink()}
              >
                Invite now
              </div>
            </div>
          </div>
        </div>
        <div className="referral__text">
          <TextAvabots />
        </div>
      </div>
    </div>
  );
};
