import React from 'react';
import Marquee from 'react-fast-marquee';
import { IconCategory } from 'components/icons';

export const BannerReferral = () => {
  return (
    <div className="referral-advertisement advertisement">
      <div className="advertisement__orange">
        <Marquee
          style={{
            height: '100%',
          }}
        >
          <div className="advertisement__list">
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">Copy Trade</div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">Limit Orders</div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">Private Tx</div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">Smart Slippage</div>
            </div>
          </div>
          <div className="advertisement__list">
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">Copy Trade</div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">Limit Orders</div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">Private Tx</div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">Smart Slippage</div>
            </div>
          </div>
          <div className="advertisement__list">
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">Copy Trade</div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">Limit Orders</div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">Private Tx</div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">Smart Slippage</div>
            </div>
          </div>
        </Marquee>
      </div>
    </div>
  );
};
