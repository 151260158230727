import React from 'react';
import Marquee from 'react-fast-marquee';
import { IconCategory } from 'components/icons';

export const BannerAbstractionBefore = () => {
  return (
    <div className="account-advertisement advertisement">
      <div className="advertisement__blue">
        <Marquee
          style={{
            height: '100%',
          }}
        >
          <div className="advertisement__list">
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">
                Buy Pepe, doge, shiba,...and more
              </div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">All in one Place</div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">
                Join the future of trading
              </div>
            </div>
          </div>
          <div className="advertisement__list">
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">
                Buy Pepe, doge, shiba,...and more
              </div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">All in one Place</div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">
                Join the future of trading
              </div>
            </div>
          </div>
          <div className="advertisement__list">
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">
                Buy Pepe, doge, shiba,...and more
              </div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">All in one Place</div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">
                Join the future of trading
              </div>
            </div>
          </div>
          <div className="advertisement__list">
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">
                Buy Pepe, doge, shiba,...and more
              </div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">All in one Place</div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">
                Join the future of trading
              </div>
            </div>
          </div>
        </Marquee>
      </div>
    </div>
  );
};

export const BannerAbstraction = () => {
  return (
    <div className="account-advertisement advertisement">
      <div className="advertisement__green">
        <Marquee
          style={{
            height: '100%',
          }}
        >
          <div className="advertisement__list">
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">
                Buy Pepe, doge, shiba,...and more
              </div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">All in one Place</div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">
                Join the future of trading
              </div>
            </div>
          </div>
          <div className="advertisement__list">
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">
                Buy Pepe, doge, shiba,...and more
              </div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">All in one Place</div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">
                Join the future of trading
              </div>
            </div>
          </div>
        </Marquee>
      </div>
    </div>
  );
};
