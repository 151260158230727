import Modal from 'react-modal';
import React, { FC } from 'react';
import { IconMenuActive } from './icons';
import Link from 'next/link';

interface IModalSuccess {
  open: boolean;
  onClose: () => void;
}

const ModalSubscriptionEmail: FC<IModalSuccess> = ({ open, onClose }) => {
  return (
    <Modal
      isOpen={open}
      ariaHideApp={false}
      onRequestClose={onClose}
      portalClassName="modal-sub-email"
      style={{
        overlay: {
          background: 'rgba(0, 0, 0, 0.80)',
          zIndex: 9999,
        },
        content: {
          width: '550px',
          height: 'auto',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          padding: '20px 20px 16px 20px',
          transform: 'translate(-50%, -50%)',
        },
      }}
    >
      <div>
        <div className="modal-sub-email__icon-close">
          <div onClick={onClose}>
            <IconMenuActive />
          </div>
        </div>
        <div className="modal-sub-email__content">
          <div className="modal-sub-email__description">
            Ava is in closed beta and is only available for invitation only. You
            can ask your friends to share their referral code or fill in the
            form below
          </div>
        </div>

        <Link
          href="https://forms.gle/dmS27EMFB4TjtrhV6"
          target="_blank"
          rel="noreferrer"
          title="Invite"
        >
          <div className="modal-sub-email__btn">Invite Me</div>
        </Link>
      </div>
    </Modal>
  );
};

export default ModalSubscriptionEmail;
