import React, { FC } from 'react';
import { IconTelegram, TextAVA, TextAVAMedium } from 'components/icons';
import { useSearchParams } from 'next/navigation';
const { Fade } = require('react-reveal');
import Image from 'next/image';

interface IBanner {
  handleActionLink: () => void;
}

export const Banner: FC<IBanner> = ({ handleActionLink }) => {
  const searchParams = useSearchParams();
  const refCode = searchParams.get('ref');
  return (
    <div>
      <div className="banner">
        <div className="banner__container">
          <div className="banner__content">
            <div className="banner__text-desktop">
              <Fade left delay={1000}>
                <div className="banner__meet-ava">
                  <Image
                    height={57}
                    width={127}
                    src={'/images/meet-ava.png'}
                    alt={'meet-ava'}
                  />
                </div>
              </Fade>
              <Fade right delay={500}>
                <h1 className="banner__title">
                  ur <span> trustless</span> Telegram trading bot
                </h1>
              </Fade>
            </div>

            <Fade bottom delay={1000}>
              <h1 className="banner__text-mobile">
                <div className="banner__sub-title">
                  Meet <span>Ava</span>
                </div>
                <div className="banner__title">
                  your <span>trustless</span>
                </div>
                <div className="banner__sub-title">Telegram trading bot</div>
              </h1>
            </Fade>

            <Fade bottom delay={1500}>
              <div
                className="banner__btn"
                onClick={() => handleActionLink()}
              >
                Hey Ava <IconTelegram />
              </div>
            </Fade>
          </div>
          <div className="banner__image">
            <Image
              height={800}
              width={800}
              src={'/images/girl-hero.gif'}
              alt="girl-hero"
            />
          </div>
        </div>
        <div className="banner__text large">
          <TextAVA />
        </div>

        <div className="banner__text medium">
          <TextAVAMedium />
        </div>
      </div>
    </div>
  );
};
