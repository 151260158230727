import React from 'react';
import { IconArrow, TextAvabots } from 'components/icons';
const { Fade } = require('react-reveal');
import Image from 'next/image';
import Link from 'next/link';

export const AccountAbstraction = () => {
  return (
    <div>
      <div className="private-key">
        <div className="private-key__content">
          <Fade left>
            <div className="private-key__title">
              <div>not your</div>
              <div className="text-relative">
                keys,
                <div className="trading">
                  <Image
                    width={178}
                    height={45}
                    src={'/images/msg-trading.png'}
                    alt="trading"
                  />
                </div>
              </div>
              <div>not your</div>
              <div className="text-relative">
                coins
                <div className="buy-now">
                  <Image
                    width={136}
                    height={45}
                    src="/images/msg-buy-now.png"
                    alt="buy-now"
                  />
                </div>
              </div>
            </div>
          </Fade>

          <div className="private-key__box-right">
            <Image
              width={439}
              height={439}
              src="/images/private-key.gif"
              alt="private-key"
            />

            <div className="private-key__desc">
              By utilizing Account Abstraction smart contract wallets, Ava helps
              you to maximize profit through trading, but she never asks for
              your private key. Nobody can withdraw funds except your
              whitelisted address. Don't trust, verify.
            </div>

            <Link
              href={
                '/blog/how-to-trade-on-a-telegram-bot-without-providing-my-private-key'
              }
              title="Read more"
              rel="noreferrer"
            >
              <div className="private-key__btn">
                <div>Read more</div>
                <div className="private-key__icon-arrow">
                  <IconArrow />
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="private-key__text">
          <TextAvabots />
        </div>
      </div>
    </div>
  );
};
