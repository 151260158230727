/* eslint-disable @next/next/no-img-element */
import React, { ReactElement, useState, useEffect } from 'react';
import Layout from 'components/layout/Layout';
import {
  BannerAbstraction,
  BannerAbstractionBefore,
} from 'components/BannerAbstraction';
import { BannerIntro } from 'components/BannerIntro';
import { BannerReferral } from 'components/BannerReferral';
import { BannerRoadmap } from 'components/BannerRoadmap';
import { SkewRunner } from 'components/SkewRunner';
import ModalSubscriptionEmail from 'components/ModalSubscriptionEmail';
import { NextSeoProps } from 'next-seo';
import { seoConfigs } from 'next-seo.config';
import {
  AccountAbstraction,
  Banner,
  Feature,
  ListToken,
  Referral,
  RoadMap,
} from 'components/hompage';
import { getReferralCode, openTelegramWithRef } from '../utils';

const HomePage = () => {
  const [isFeatureActive, setIsFeatureActive] = useState<boolean>(false);
  const [isBannerNotActive, setIsBannerNotActive] = useState<boolean>(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);

  const toggleVisible = () => {
    const elReferral = document.getElementById('referral');
    const elBanner = document.getElementById('feature-slide');
    const distanceReferral = elReferral?.getBoundingClientRect().top || 0;
    const distanceBanner = elBanner?.getBoundingClientRect().top || 0;

    if (distanceReferral < window.innerHeight / 3 - 100) {
      setIsFeatureActive(true);
    } else {
      setIsFeatureActive(false);
    }

    if (distanceBanner > window.innerHeight / 3 + 120) {
      setIsBannerNotActive(true);
    } else {
      setIsBannerNotActive(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);
    return () => window.removeEventListener('scroll', toggleVisible);
  }, []);

  useEffect(() => {
    if (isShowModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'inherit';
    }
  }, [isShowModal]);

  const handleActionLink = () => {
    const refCode = getReferralCode();
    openTelegramWithRef(refCode);
  };

  return (
    <div>
      <Banner handleActionLink={handleActionLink} />

      <div className="skew-banner-desktop">
        <SkewRunner bgBottom={'#5645FF'} rotate={'-2deg'} offset={'-3.5%'}>
          <BannerIntro />
        </SkewRunner>
      </div>

      <div className="skew-banner-desktop-large">
        <SkewRunner bgBottom={'#5645FF'} rotate={'-2deg'} offset={'-3.5%'}>
          <BannerIntro />
        </SkewRunner>
      </div>

      <div className="skew-banner-mobile">
        <SkewRunner heightContain={'40px'}>
          <BannerIntro />
        </SkewRunner>
      </div>

      <div className={!isBannerNotActive ? 'private-key-full' : ''}>
        <AccountAbstraction />
      </div>
      <div
        className={`${isFeatureActive ? 'not-show' : ''} ${
          !isBannerNotActive ? 'not-active' : ''
        } group-banner`}
        id="group-banner"
      >
        <SkewRunner bgTop={'#5645FF'} bgBottom={'#2a237f'}>
          <BannerAbstractionBefore />
        </SkewRunner>

        <div className="banner-ontop">
          <SkewRunner rotate={'-5deg'}>
            <BannerAbstraction />
          </SkewRunner>
        </div>
      </div>

      <Feature />

      <div id="referral" style={{ zIndex: 9, position: 'relative' }}>
        <Referral handleActionLink={handleActionLink} />
      </div>

      <SkewRunner
        bgTop={'#5645FF'}
        bgBottom={'#FF2497'}
        rotate={'2deg'}
        direction="ltr"
      >
        <BannerReferral />
      </SkewRunner>
      <ListToken />
      <RoadMap />

      <SkewRunner bgTop={'#5645FF'} bgBottom={'#060534'} rotate={'-3deg'}>
        <BannerRoadmap />
      </SkewRunner>
      {isShowModal && (
        <ModalSubscriptionEmail
          open={isShowModal}
          onClose={() => setIsShowModal(false)}
        />
      )}
    </div>
  );
};

export default HomePage;

HomePage.getLayout = function getLayout(page: ReactElement) {
  const seoProps: NextSeoProps = {
    title: `${seoConfigs.title}`,
  };
  return (
    <>
      <Layout {...seoProps}>{page}</Layout>
    </>
  );
};
